import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';

export const state = {
  jobLoading: true,
  jobFilesLoading: true,
  jobInputsRefreshing: true,
  jobOutputsRefreshing: true,
  job: {},
  jobFiles: [],
  jobError: false,
  jobOutputSearch: "",
  jobInputSearch:"",
}

export const mutations = {
  //cannot do async in mutations.
  populateJob(state, job){
    state.job = job
  },
  populateJobFiles(state, jobFiles){
    state.jobFiles = jobFiles
  },
  populateJobInputFiles(state, inputs){
    state.jobFiles.inputs = inputs
  },
  populateJobOutputFiles(state, outputs){
    state.jobFiles.outputs = outputs
  },
  jobLoaded(state){
    state.jobLoading = false
  },
  jobLoad(state){
    state.jobLoading = true;
  },
  jobInputsRefreshed(state){
    state.jobInputsRefreshing = false
  },
  jobInputsRefresh(state){
    state.jobInputsRefreshing = true;
  },
  jobOutputsRefreshed(state){
    state.jobOutputsRefreshing = false;
  },  
  jobOutputsRefresh(state){
    state.jobOutputsRefreshing = true;
  },  
  jobFilesLoaded(state){
    state.jobFilesLoading = false;
  },  
  jobFilesLoad(state){
    state.jobFilesLoading = true;
  },
  jobError(state, payload){
    state.jobError = payload
  },
  populateJobOutputSearch(state,payload){
    state.jobOutputSearch=payload
  },
  clearJobOutputSearch(state){
    state.jobOutputSearch=""
  },
  populateJobInputSearch(state,payload){
    state.jobInputSearch=payload
  },
  clearJobInputSearch(state){
    state.jobInputSearch=""
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadJob({commit, dispatch}, jobDetails){
    commit("load")
    try {
      commit("jobLoad")
      const workflowId = jobDetails.workflowId
      const projectName = jobDetails.projectName
      const jobId = jobDetails.jobId
      const step = jobDetails.step
      const res = await axios.get(`${API_URL}/jobs/${projectName}/${workflowId}/${step}/${jobId}`, await authHeader())
      commit('populateJob', res.data)
      commit('jobLoaded')
      commit('jobError', false)
    } catch (e) {
      commit('jobError', true)
      dispatch('notify', {message:"Error Loading Job: " + e.message, status:false})
    }    
  },
  async loadJobFiles({commit, dispatch}, jobDetails){
    try{
      commit("jobFilesLoad")
      const workflowId = jobDetails.workflowId
      const projectName = jobDetails.projectName
      const jobId = jobDetails.jobId
      const step = jobDetails.step
      const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_dependencies&get_status`, await authHeader())
      commit('populateJobFiles', res.data)
      commit('jobFilesLoaded')
      commit('jobError', false)
      commit('clearJobInputSearch')
      commit('clearJobOutputSearch')
      commit('jobOutputsRefreshed')
      commit('jobInputsRefreshed')
    }
    catch(e){
      commit('jobError', true)
      dispatch('notify', {message:"Error Loading Job Files: " + e.message, status:false})
    }
  },
  async loadJobFilesInputStatusOnly({commit, state, dispatch}, jobDetails){
    commit('jobInputsRefresh')
    const workflowId = jobDetails.workflowId
    const projectName = jobDetails.projectName
    const jobId = jobDetails.jobId
    const step = jobDetails.step
    const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_status`, await authHeader())
        
    let inputFiles = res.data.inputs
    let stateInputFiles = state.jobFiles.inputs
    for(let i=0; i<stateInputFiles.length; i++){
      for(let j=0; j<stateInputFiles[i].urls.length; j++){
        inputFiles[i].urls[j].provided_by = stateInputFiles[i].urls[j].provided_by
      }
    }

    commit('populateJobInputFiles', inputFiles)
    
    if(state.jobInputSearch!==""){
      dispatch('searchJobInputFiles')
    }
    commit('jobError', false)
    commit('jobInputsRefreshed')
  },
  async loadJobFilesOutputStatusOnly({commit, state, dispatch}, jobDetails){
    commit('jobOutputsRefresh')
    const workflowId = jobDetails.workflowId
    const projectName = jobDetails.projectName
    const jobId = jobDetails.jobId
    const step = jobDetails.step
    const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_status`, await authHeader())
    
    let outputFiles = res.data.outputs
    let stateOutputFiles = state.jobFiles.outputs
    for(let i=0; i<stateOutputFiles.length; i++){
      for(let j=0; j<stateOutputFiles[i].urls.length; j++){
        outputFiles[i].urls[j].required_by = stateOutputFiles[i].urls[j].required_by
      }
    }

    commit('populateJobOutputFiles', outputFiles)
    
    if(state.jobOutputSearch!==""){
      dispatch('searchJobOutputFiles')
    }

    commit('jobError', false)
    commit('jobOutputsRefreshed')
  },
  populateJobInputSearch({commit}, search){
    commit("populateJobInputSearch", search)
  },
  populateJobOutputSearch({commit}, search){
    commit("populateJobOutputSearch", search)
  },
  searchJobInputFiles({state, commit}){
    try{
      let likeExpr = RegExp.escape(state.jobInputSearch)
      let jobFiles = state.jobFiles.inputs

      let foundFiles = []
      jobFiles.forEach(jf => {
        let match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".").toLowerCase()).exec(jf.dataset.toLowerCase()) != null;
        if(!match){
          //search urls which takes longer so avoid if possible
          let urls = jf.urls
          urls.forEach(url => {
            match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".").toLowerCase()).exec(url.url.toLowerCase()) != null;
          })
        }
        if(match){
          foundFiles.push(jf)
        }
      })
      if(foundFiles.length>0){
        commit('populateJobInputFiles', foundFiles)
      }
      else{
        commit('populateJobInputFiles', [{dataset: "No results returned", urls: [{url: "", exists: false}]}])
      }
    }
    catch(e){
      console.log(e)
    }
    commit('jobLoaded')
    commit('jobError', false)
  },
  searchJobOutputFiles({state, commit}){
    try{
      let likeExpr = RegExp.escape(state.jobOutputSearch)
      let jobFiles = state.jobFiles.outputs
      let foundFiles = []
      jobFiles.forEach(jf => {
        let match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".").toLowerCase()).exec(jf.dataset.toLowerCase()) != null;
        if(!match){
          //search urls which takes longer so avoid if possible
          let urls = jf.urls
          urls.forEach(url => {
            match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".").toLowerCase()).exec(url.url.toLowerCase()) != null;
          })
        }
        if(match){
          foundFiles.push(jf)
        }
      })
      if(foundFiles.length>0){
        commit('populateJobOutputFiles', foundFiles)
      }
      else{
        commit('populateJobOutputFiles', [{dataset: "No results returned", urls: [{url: "", exists: false}]}])
      }
    }
    catch(e){
      console.log(e)
    }
    commit('jobLoaded')
    commit('jobError', false)
  },
  async clearJobInputSearch({commit}, jobDetails){
    commit('clearJobInputSearch')
    const workflowId = jobDetails.workflowId
      const projectName = jobDetails.projectName
      const jobId = jobDetails.jobId
      const step = jobDetails.step
      const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_dependencies&get_status`, await authHeader())
      commit('populateJobFiles', res.data)
  },
  async clearJobOutputSearch({commit}, jobDetails){
    commit('clearJobOutputSearch')
    const workflowId = jobDetails.workflowId
    const projectName = jobDetails.projectName
    const jobId = jobDetails.jobId
    const step = jobDetails.step
    const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_dependencies&get_status`, await authHeader())
    commit('populateJobFiles', res.data)
  }
}

export const getters = {
  getJob: state => state.job,
  getJobFiles: state => state.jobFiles,
  jobLoading: state => state.jobLoading,
  jobFilesLoading: state => state.jobFilesLoading,
  jobError: state => state.jobError,
  jobInputsRefreshing: state => state.jobInputsRefreshing,
  jobOutputsRefreshing: state => state.jobOutputsRefreshing
}