import {Amplify} from "aws-amplify";
import awsconfig from "../aws-exports";

export function configureAmplify() {
  Amplify.configure(awsconfig);
}

function init() {
  configureAmplify();
}

export default init;