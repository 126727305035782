const workflowActions = {
  workflowDictionary: {
    'pending': ['activate', 'abandon', 'initialise', 'delete'],
    'active': ['pause', 'complete', 'abandon', 'initialise'], 
    'paused': ['activate', 'complete', 'abandon', 'initialise'],
    'error': ['delete']
  },
  superCommandDictionary: [
    {
      'workflowStatus': 'pending', 
      'jobCommandDict': [
        {'jobStatuses': ['UNKNOWN', 'RUNNABLE', 'PENDING'], 'commands': ['hold', 'status']}
      ]
    },
    {
      'workflowStatus': 'active', 
      'jobCommandDict': [
        {"jobStatuses": ['UNKNOWN'], "commands": ['hold', 'status']}, 
        {"jobStatuses": ['PENDING'], "commands": ['hold', 'force_run', 'status']}, 
        {"jobStatuses": ['RUNNABLE'], "commands": ['run', 'hold', 'status']}, 
        {"jobStatuses": ['SUBMITTED'], "commands": ['cancel', 'terminate', 'reset', 'status']}, 
        {"jobStatuses": ['RUNNING'], "commands": ['terminate', 'reset', 'status']}, 
        {"jobStatuses": ['COMPLETED'], "commands": ['remove', 'reset', 'rerun', 'rerun inputs']},
        {"jobStatuses": ['FAILED', 'INCOMPLETE'], "commands": ['retry', 'remove', 'rerun', 'rerun inputs', 'reset']}, 
        {"jobStatuses": ['HELD'], "commands": ['release', 'rerun', 'rerun inputs']}
      ]
    },
    {
      'workflowStatus': 'paused', 
      'jobCommandDict': [
        {"jobStatuses": ['UNKNOWN', 'PENDING', 'RUNNABLE'], "commands": ['hold', 'status']}, 
        {"jobStatuses": ['SUBMITTED'], "commands": ['cancel', 'terminate', 'status']}, 
        {"jobStatuses": ['RUNNING'], "commands": ['terminate', 'status']}, 
      ]
    },
    {
      'workflowStatus': 'abandoned', 
      'jobCommandDict': [
        {'jobStatuses': ['SUBMITTED'], "commands": ['cancel', 'terminate']},
        {'jobStatuses': ['RUNNING'], "commands": ['terminate']}
      ]
    },
  ]
}

export default workflowActions