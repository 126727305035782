import { Auth } from 'aws-amplify'


export const authHeader = async() => {
  // return authorization header with jwt token
  const user = await Auth.currentAuthenticatedUser({bypassCache: false })   
  let token = user.signInUserSession.idToken.jwtToken
  if (token) {
      return { headers: {'content-type': 'application/x-www-form-urlencoded', 'Authorization': token }};
  } else {
      return {};
  }
}