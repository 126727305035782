import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants'
import likeSearch from '../../utils/likeSearch'

export const state = {
  loading: true,
  allWorkflows: [{}],
  filteredWorkflows: [{}],
  selectedStatus: "",
  selectedProject: "",
  projectNames: [],
  statuses: [{}],
  workflowSearch: ""
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflows(state, workflows){
    state.allWorkflows = workflows
    state.filteredWorkflows = workflows
  },
  populateProjects(state, projects){
    state.projectNames = projects
  },
  populateStatuses(state, statuses){
    state.statuses = statuses
  },
  populateWorkflowSearch(state,searchTerm){
    state.workflowSearch = searchTerm
  },
  loaded(state){
    state.loading = false
  },
  load(state){
    state.loading = true;
  },
  selectProject(state, selectedProject){
    state.selectedProject = selectedProject
  },
  selectStatus(state, selectedStatus){
    state.selectedStatus = selectedStatus
  },
  filterWorkflows(state, workflows){
    state.filteredWorkflows = workflows
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async getAllWorkflows({commit, state, dispatch},project){
    commit("load")
    try {    
      let workflows = [] 
      let res = await axios.get(`${API_URL}/workflows/${project}`, await authHeader())
      res.data.forEach(d => workflows.push(d))
      workflows.sort((a,b) => {return b.created - a.created})
      commit('populateWorkflows', workflows)
      let statusNames = workflows.map(wf => wf.status).filter((value, index, self) => self.indexOf(value) === index)
      let activeStatuses = state.statuses.filter(as => as.active).map(as => as.name)
      let statuses = statusNames.map(wf => ({active: activeStatuses.includes(wf), name: wf}))
      dispatch('filterWorkflowStatus')
      commit('populateStatuses', statuses)
      commit('loaded')
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    }    
  },
  filterWorkflowProject({commit, state}, project){
    commit("load")
    let filteredWorkflows = state.allWorkflows.filter(wf => wf.project == project)
    commit('filterWorkflows', filteredWorkflows)     
    commit('selectProject', project)   
    commit('selectStatus', "") //reset the status filter 
    commit('loaded')
  },
  filterWorkflowStatus({commit, state}){
    commit("load")
    let activeStatuses = state.statuses.filter(s => s.active).map(s => s.name)
    let filteredWorkflows = state.allWorkflows.filter(wf => activeStatuses.includes(wf.status))
    commit('filterWorkflows', filteredWorkflows) 
    commit('loaded')
  },
  clearWorkflowSearch({commit, state}){
    commit('populateWorkflowSearch',"")
  },
  searchWorkflows({commit, state}, searchTerm){
    commit('populateWorkflowSearch', searchTerm)
    let workflows = []
    if(searchTerm!==""){
      state.filteredWorkflows.forEach(workflow => {
        let likeExpr = RegExp.escape(searchTerm)
        let match = new RegExp(likeExpr.replace("%", ".*").replace("_", ".")).exec(workflow.name) != null;
        if(match){
          workflows.push(workflow)
        }
      })      
    }
    else{
      workflows = state.filteredWorkflows
    }
    commit('filterWorkflows', workflows)
  },
  setAllStatuses({commit, state, dispatch}){
    let allStatusTrue = state.statuses.map(status => ({active:true, name:status.name}))
    commit('populateStatuses', allStatusTrue)
    dispatch('filterWorkflowStatus')
  }
}

export const getters = {
  getWorkflows: state => () => state.allWorkflows,
  getFilteredWorkflows: state => state.filteredWorkflows,
  isLoading: state => state.loading,
  getSelectedProject: state => state.selectedProject,
  getSelectedStatus: state => state.selectedStatus,
  getStatuses: state => state.statuses
}