<template>
  <select id="filter"
        name="filter"
        class="form-control mt-1"
        v-model="selectedModel"
        @change="filterValues">
    <option v-if="!hideMessage" value="" disabled :selected="selectMessage">{{ message }}</option>    
    <option v-for="item, index in items" v-bind:value="item" :key="index">{{ item }}</option>
    <option v-if="showClear" value="">Clear</option>    
  </select>
</template>

<script>

export default {
  name: 'SelectList',
  props: {
    message: String,
    items: Array,
    showClear: Boolean,
    selectMessage: Boolean,
    loadValue: Boolean,
    hideMessage: Boolean
  },
  data(){
    return {
      selectedModel:""
    }
  },
  methods: {
    filterValues(){
      this.$emit('filterList', this.selectedModel)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#filter{
  width: 10rem;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
#filter select{
   border: 1px solid rgb(255, 255, 255);
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}
</style>
