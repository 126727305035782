<template>
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="modal-header">
                <slot name="header">
                  Session Expiry
                </slot>
              </div>
              <div class="modal-body">
                <slot name="body">
                  Your session is about to expire and you will be logged out in <span class="timer">{{minutes}}m:{{seconds}}s</span>
                </slot>
              </div>
              <div class="modal-footer">
                <slot name="footer">
                  Select OK to refresh and continue
                  <button class="modal-default-button" @click="closeExpiryModal()">
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
</template>

<script>
export default {
  name: 'SessionExpiry',  
  data(){
    return {
      timerCount: 300,
      minutes: 0,
      seconds: 0
    }
  },
  methods: {
    closeExpiryModal(){
      this.$emit('setIdleFalse')
    }
  },
  watch: {
    timerCount: {
      handler(value){
        if(value > 0){
          setTimeout(() => {
            this.timerCount--;
            this.minutes = Math.floor(this.timerCount/60)
            this.seconds = Math.floor(this.timerCount - this.minutes * 60)
          }, 1000)
        }
        else{
          this.$emit('expiryLogout');
          this.closeExpiryModal()
        }
      },
      immediate:true
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 25rem;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  height: 2rem;
  margin-top: 0;
  background-color: #297737ec;
  color:white;
  font-weight: bold;
  font-size:18px;
  line-height: 2;
  text-align: center;
}

.modal-body {
  padding: 1rem;
}

.modal-default-button {
  background-color: #297737ec;
  color: white;
  font-weight: bold;
  font-size: 16px;
  border: 2px solid rgba(6, 31, 10, 0.795);
  border-radius: 5px;
  height: 2rem;
  width:5rem;
  float: right;
  cursor: pointer;
}

.modal-footer {
  padding:1rem;
  height: 2rem;
}

.timer{
  font-weight: bold;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>