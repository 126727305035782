import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';
import store from '../../store'


export const state = {
  jobsLoading: true,
  jobsStepByStatus: {},
  jobsError: false,
  jobsSearch: "",
  allJobsStepByStatus: [],
}

export const mutations = {
  //cannot do async in mutations.
  populateJobs(state, jobs){
    state.jobsStepByStatus = jobs
  },
  populateAllJobs(state, jobs){
    state.allJobsStepByStatus = jobs
  },
  jobsLoaded(state){
    state.jobsLoading = false
  },
  jobsLoad(state){
    state.jobsLoading = true;
  },
  jobsError(state, payload){
    state.jobsError = payload
  },
  populateJobsSearch(state,payload){
    state.jobsSearch=payload
  },
  replaceWorkflowJobData(state, workflowJobData){
    const jobIndex = state.jobsStepByStatus.findIndex((job) => job.id === workflowJobData.definition.id)
    const workflowJobFromData = {
      id: workflowJobData.definition.id, 
      ui_id: workflowJobData.definition.ui_id, 
      step: workflowJobData.definition.step,
      status: workflowJobData.p2020_status,
      p2020: workflowJobData.p2020_status,
      aws: workflowJobData.aws_status,
    }
    state.jobsStepByStatus[jobIndex] = workflowJobFromData
    
  },
  clearJobsSearch(state){
    state.jobsSearch=""
  },
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadJobs({commit, dispatch}, workflowJobDetails){
    commit("load")
    try {
      commit("jobsLoad")
      let splitUrl = window.location.href.split('/')
      let urlArgs = splitUrl.splice(6 - splitUrl.length)
      const workflowId = workflowJobDetails.workflowId
      const projectName = workflowJobDetails.projectName
      if(urlArgs[0]!==undefined && urlArgs[0]!=="ALL" && urlArgs[1]!==undefined){
        // selected a step AND a status
        const res = await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${urlArgs[1]}?status=${urlArgs[0]}`, await authHeader())
        let data = res.data
        data.forEach(d => d['step']=urlArgs[1])
        commit('populateJobs', data)
        commit('populateAllJobs', data)
      }      
      else if(urlArgs[0]==='ALL' && urlArgs[1]!==undefined){
        // selected a step AND a status
        const res = await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${urlArgs[1]}`, await authHeader())
        let data = res.data
        data.forEach(d => d['step']=urlArgs[1])
        commit('populateJobs', data)
        commit('populateAllJobs', data)
      }      
      commit('jobsLoaded')
    } catch (e) {
      commit('jobsError', true)
      dispatch('notify', {message:"Error Loading Job: " + e.message, status:false})
    }    
  },
  populateJobsSearch({commit}, search){
    commit("populateJobsSearch", search)
  },
  async searchJobsAcrossWorkflow({state, commit}, workflowDetails){
    try{
      // Searches jobs by ui_id
      commit("jobsLoad")
      let foundJobs = []
      const projectName = workflowDetails.projectName
      const workflowId = workflowDetails.workflowId
      // get initial jobs result
      let res = await axios.get(`${API_URL}/ui_id_search/${projectName}/${workflowId}?pattern=${state.jobsSearch}`, await authHeader())
      let body = {}
      if(res.data.length > 0){
        body = {"project": projectName, "workflow_id": workflowId, "job_list": res.data}
      }      
      // send post with above results in body to get status data
      let jobs_list_statuses_res = await axios.post(`${API_URL}/jobs_list_statuses`, body, await authHeader())
      if(jobs_list_statuses_res.data.length > 0){
        jobs_list_statuses_res.data.forEach(j => {
          foundJobs.push(j)
        })
      }
      commit('populateJobs', foundJobs)
    }
    catch(e){
      console.log(e)
    }
    commit('jobsLoaded')
  },
  async searchJobs({state, commit}, workflowDetails){
    try{
      // Searches jobs by ui_id
      commit("jobsLoad")
      let foundJobs = []
      const projectName = workflowDetails.projectName
      const workflowId = workflowDetails.workflowId
      const step = workflowDetails.step

      let splitUrl = window.location.href.split('/')
      let urlArgs = splitUrl.splice(6 - splitUrl.length)
      // selected a step AND a status
      let res = urlArgs[0]!==undefined && urlArgs[0]!=="ALL" && urlArgs[1]!==undefined ?
        await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${step}?ui_id=${state.jobsSearch}&status=${urlArgs[0]}`, await authHeader()) :
        await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${step}?ui_id=${state.jobsSearch}`, await authHeader())

      if(res.data.length > 0){
        res.data.forEach(j => {
          j['step']= step
          foundJobs.push(j)
        })
      }

      commit('populateJobs', foundJobs)
    }
    catch(e){
      console.log(e)
    }
    commit('jobsLoaded')
  },
  setJobsLoad({commit}){
    commit('jobsLoaded')
  },
  clearJobsSearch({state, commit}){
    commit('clearJobsSearch')
    let jobs = state.allJobsStepByStatus
    commit('populateJobs', jobs)
    commit('populateAllJobs', jobs)
  },
  async refreshJobData({dispatch, commit}, jobRowDetails){
    try{
      let splitUrl = window.location.href.split('/')
      let urlArgs = splitUrl.splice(3 - splitUrl.length)
      const workflowId = urlArgs[2]
      const project = urlArgs[1]
      const step = jobRowDetails.step
      const jobId = jobRowDetails.id
      const res = await axios.get(`${API_URL}/jobs/${project}/${workflowId}/${step}/${jobId}`, await authHeader())
      let jobRowData = res.data
      commit('replaceWorkflowJobData', jobRowData)
      dispatch('notify', {message:`${jobRowData.definition.ui_id} refreshed`, status:true})
    }
    catch(err){
      console.error(err)
    }
  }
}

export const getters = {
  getJobs: state => state.jobsStepByStatus,
  getJobsSearch: state => state.jobsSearch,
  jobsLoading: state => state.jobsLoading,
  jobsError: state => state.jobsError
}