import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';

const sortOrder = [
  {order:1, source: "optional"},
  {order:2, source: "job_override"},
  {order:3, source: "job_var"},
]

export const state = {
  jobConfig: [],
  jobOverrides: [],
  jobConfigLoading: true,
  jobConfigDetails: {},
  addingNewOverride: false,
  originalJobVersion: "",
  originalJobSource: ""
}

export const mutations = {
  jobConfig(state, config){
    state.jobConfig = config
  },
  setJobConfigDetails(state, details){
    state.jobConfigDetails = details
  },
  jobConfigLoad(state){
    state.jobConfigLoading = true
  },
  jobConfigLoaded(state){
    state.jobConfigLoading = false
  },
  addingNewOverride(state){
    state.addingNewOverride = true
  },
  newOverrideAdded(state){
    state.addingNewOverride = false
  },
  updateJobConfigOverrides(state, overrides){
    state.jobOverrides = overrides
  },
  setOriginalJobVersion(state, version){
    state.originalJobVersion = version
  },
  setOriginalJobSource(state, source){
    state.originalJobSource = source
  },
  clearJobOverrides(state){
    state.jobOverrides = []
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadJobConfig({commit, dispatch}, jobDetails){
    commit("load")
    try {
      commit("jobConfigLoad")
      commit("clearJobOverrides")
      const project = jobDetails.project;
      const workflow_id = jobDetails.workflow_id;
      const step = jobDetails.step;
      const job_id = jobDetails.job_id
      commit("setJobConfigDetails", {project, workflow_id, step, job_id})

      const res = await axios.get(`${API_URL}/job_config/${project}/${workflow_id}/${step}/${job_id}`, await authHeader())      
      let sortedData = {"definition":res.data.definition, "variables": []}
      res.data.variables.forEach(configVar => sortedData.variables.push({"order": 
        configVar.source==="optional"? 1: 
        configVar.source==="job_override" ? 2:
         configVar.source==="step_override"? 3:
         configVar.source==="job_var"? 4:
         configVar.source==="static"? 5:
         configVar.source==="auto"? 6:
         configVar.source==="job_input"? 7:
         configVar.source==="job_output"? 8:
         999, 
        ...configVar
      }))
      sortedData.variables.sort((a,b) => a.order - b.order)

      commit('jobConfig', sortedData)
      commit("updateJobConfigOverrides", res.data.variables.filter(jcv => jcv.source==="job_override"))
      commit("setOriginalJobVersion", res.data.definition.version)
      commit("setOriginalJobSource", res.data.definition.source)
      commit('jobConfigLoaded')
    } catch (e) {
      dispatch('notify', {message:"Error Loading Job Config: " + e.message, status:false})
    }   
  },
  async updateJobConfig({commit, state}, variablesToOverride){
    commit("jobConfigLoad")
    let jobOverrides = state.jobOverrides
    if(jobOverrides.length>0){
      let jobOverrideExists = jobOverrides.find(jo => jo!==null && jo.name === variablesToOverride.name)
      if(jobOverrideExists){
        jobOverrides = jobOverrides.splice(jo => jo.name === variablesToOverride.name)
      }
    }
    jobOverrides.push({"name": variablesToOverride.name, "value": variablesToOverride.value})
    commit("updateJobConfigOverrides", jobOverrides)
    commit('jobConfigLoaded')
  },
  async saveJobConfig({commit, state, dispatch}){
    commit("jobConfigLoad")
    try{
      let stateConfig = state.jobConfig  
      const project = state.jobConfigDetails.project;
      const workflow_id = state.jobConfigDetails.workflow_id;
      const step = state.jobConfigDetails.step;
      const job_id = state.jobConfigDetails.job_id  
      let jobOverrideVariables = state.jobOverrides
      let jobOverrides = []

      if(stateConfig.definition.source==="job_override"){
        jobOverrides = {"version": stateConfig.definition.version, "variables": jobOverrideVariables}
      }
      else{
        jobOverrides = {"variables": jobOverrideVariables}
      }
      let body = {"project": project, "workflow_id": workflow_id, "step": step, "job_id": job_id, "overrides": jobOverrides}
      await axios.post(`${API_URL}/modify_job`, body, await authHeader())
      dispatch('notify', {message:"Job Config Details Updated ", status:true})
      dispatch('loadJobConfig', {project,workflow_id,step,job_id})
    }
    catch(e){
      console.log(e)
    }
    commit('jobConfigLoaded')
  },
  async resetAllJobOVerrides({commit, state, dispatch}){
    commit("jobConfigLoad")
    try{
      const project = state.jobConfigDetails.project;
      const workflow_id = state.jobConfigDetails.workflow_id;
      const step = state.jobConfigDetails.step;  
      const job_id = state.jobConfigDetails.job_id

      let body = {project, workflow_id, step, job_id, "overrides": {}}
      await axios.post(`${API_URL}/modify_job`, body, await authHeader())
      commit("clearJobOverrides")
      dispatch('loadJobConfig', {project,workflow_id,step,job_id})
      dispatch('notify', {message:"Job Config Overrides Cleared", status:true})
    }catch(e){
      dispatch('notify', {message:"Failed to Reset Job Overrides", status:false})
    }
    commit("jobConfigLoaded")
  },

  async updateJobVersion({commit, state, dispatch}){
    let stateVersion = state.originalJobVersion
    let stateConfig = state.jobConfig
    if(stateVersion!==stateConfig.definition.version){
      stateConfig.definition.source = "job_override"
    }
    else{
      stateConfig.definition.source = state.originalJobSource
    }
    const project = state.jobConfigDetails.project;
    const workflow_id = state.jobConfigDetails.workflow_id;
    const step = state.jobConfigDetails.step;
    const job_id = state.jobConfigDetails.job_id  
    //dispatch('loadJobConfig', {project,workflow_id,step,job_id})
    dispatch('notify', {message:"Job Config Version updated, please save to finalise ", status:true})
  },
  async resetJobVersionOverride({commit, state, dispatch}){
    commit("jobConfigLoad")
    try{
      let stateConfig = state.jobConfig
      const project = state.jobConfigDetails.project;
      const workflow_id = state.jobConfigDetails.workflow_id;
      const step = state.jobConfigDetails.step;  
      const job_id = state.jobConfigDetails.job_id

      let jobOverrideVariables = state.jobOverrides

      let overridesAlreadyFeatured = stateConfig.variables
        .filter(jo => jobOverrideVariables
          .map(jov => jov.name)
          .includes(jo.name))

      if(overridesAlreadyFeatured.length>0){
        overridesAlreadyFeatured.forEach(so => {if(so.name!==undefined && so.value!==undefined){
          jobOverrideVariables.push({"name": so.name, "value":so.value})
        }})        
      }

      let jobOverrides = {"variables": jobOverrideVariables}
  
      let body = {project, workflow_id, step, job_id, "overrides": jobOverrides}
      await axios.post(`${API_URL}/modify_job`, body, await authHeader())
      dispatch('loadJobConfig', {project,workflow_id,step,job_id})
      dispatch('notify', {message:"Job Config Version Reset", status:true})
    }catch(e){
      dispatch('notify', {message:"Failed to Reset Job Version", status:false})
    }
    commit("jobConfigLoaded")
  },
  addJobConfigOverride({commit, state, dispatch}, newOverride){
    commit("addingNewOverride")
    let jobOverrides = state.jobOverrides
    if(jobOverrides.length>0){
      let jobOverrideExists = jobOverrides.find(jo => jo!==null && jo.name === newOverride.name)
      if(jobOverrideExists){
        dispatch('notify', {message: "this variable already exists as an override", status: false})
      }
      else{
        jobOverrides.push({"name": newOverride.name, "value": newOverride.value})
      }
    }
    else{
      jobOverrides.push({"name": newOverride.name, "value": newOverride.value})
    }

    commit("updateJobConfigOverrides", jobOverrides)

    let jobConfig = state.jobConfig
    let jobConfigExists = jobConfig.variables.find(jcv => jcv!==null && jcv.name === newOverride.name)
    if(jobConfigExists){
      dispatch('notify', {message: "this variable already exists in the job config", status: false})
    }
    else{
      jobConfig.variables.push({"name": newOverride.name, "source": "job_override", "type": "str", "value": newOverride.value})
    }
    commit('jobConfig', jobConfig)

    commit('newOverrideAdded')    
    dispatch('notify', {message:"Job Config Override Added, please save to finalise ", status:true})
  },
  removeJobOverride({state, dispatch, commit}, overrideToRemove){
    let jobOverrides = state.jobOverrides
    if(jobOverrides.length>0){
      let jobOverrideExists = jobOverrides.find(jo => jo!==null && jo.name === overrideToRemove.name)
      if(jobOverrideExists){
        jobOverrides = jobOverrides.filter(jo => jo!==null && jo.name !== overrideToRemove.name)
        commit("updateJobConfigOverrides", jobOverrides)
      }
    }

    let jobConfig = state.jobConfig
    let jobConfigExists = jobConfig.variables.find(jcv => jcv!==null && jcv.name === overrideToRemove.name)
    if(jobConfigExists){
      jobConfig.variables = jobConfig.variables.filter(jcv => jcv!==null && jcv.name !== overrideToRemove.name)
    }
    commit('jobConfig', jobConfig)
    dispatch('notify', {message:"Job Config Override removed, please save to finalise", status:true})
  }
}

export const getters = {
  getJobConfig: state => state.jobConfig,
  jobConfigLoading: state => state.jobConfigLoading,
  getJobOverrides: state => state.jobOverrides,
  getAddingNewOverride: state => state.addingNewOverride
}