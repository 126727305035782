import axios from 'axios'
import router from '../../router'
import {authHeader} from '../../middleware/auth-header'
import { Auth } from 'aws-amplify'
import API_URL from '../../constants'
import store from '../../store'
import workflowActions from '../../workflowcommands'

export const state = {
  availableActions: [],
  availableJobActions: [],
  selectedAction: "",
  selectedJobAction: "",
  selectedJobStepAction: "",
  selectedStepAction: "",
  failedJobActions: "",
  incompleteJobActions: "",
  runningJobActions: "", 
  submittedJobActions: "", 
  runnableJobActions: "",
  pendingJobActions : "",
  unknownJobActions : "",
  completedJobActions : "",
  heldJobActions : "",
  availableStepActions: "",
  depdendentJobs: []
}

export const mutations = {
  populateWorkflowActions(state, availableActions){
    state.availableActions = availableActions
  },
  updateWorklowAction(state, selectedAction){
    state.selectedAction = selectedAction
  },
  populateWorkflowJobActions(state, workflowJobActions){
    state.availableJobActions = workflowJobActions
  },
  updateWorkflowJobAction(state, selectedJobAction){
    state.selectedJobAction = selectedJobAction
  },
  populateWorkflowStepActions(state, workflowStepActions){
    state.availableStepActions = workflowStepActions
  },
  updateWorkflowStepAction(state, selectedStepAction){
    state.selectedStepAction = selectedStepAction
  },
  updateWorkflowJobStepAction(state, selectedJobStepAction){
    state.selectedJobStepAction = selectedJobStepAction
  },
  // list of available mutations for the columns on the Workflow > Steps > column jobs page
  updateUnknownJobActions(state, availableActions){
    state.unknownJobActions = availableActions
  },
  updatePendingJobActions(state, availableActions){
    state.pendingJobActions = availableActions
  },
  updateRunnableJobActions(state, availableActions){
    state.runnableJobActions = availableActions
  },
  updateSubmittedJobActions(state, availableActions){
    state.submittedJobActions = availableActions
  },
  updateRunningJobActions(state, availableActions){
    state.runningJobActions = availableActions
  },
  updateCompletedJobActions(state, availableActions){
    state.completedJobActions = availableActions
  },
  updateHeldJobActions(state, availableActions){
    state.heldJobActions = availableActions
  },
  updateFailedJobActions(state, availableActions){
    state.failedJobActions = availableActions
  },
  updateIncompleteJobActions(state, availableActions){
    state.incompleteJobActions = availableActions
  },
  setDepdendentJobs(state, depdendentJobs){
    state.depdendentJobs = depdendentJobs
  },
  clearSelectedActions(state){
    state.selectedJobAction = ""
    state.selectedAction = ""
    state.selectedJobStepAction = ""
    state.selectedJobStepAction
  }
}

export const actions = {
  ///
  /// EVERYTHING ACTIONS
  ///
  clearActions({commit}){
    commit('clearSelectedActions')
  },
  ///
  /// WORKFLOW RELATED ACTIONS
  ///
  loadWorkflowActions({commit, dispatch}, workflowStatus){
    try {
      const availableActions = workflowActions.workflowDictionary[workflowStatus]
      commit('populateWorkflowActions', availableActions)
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  selectWorkflowAction({commit}, input){
    commit('updateWorklowAction', input.target.value) 
  },  
  async updateWorkflowStatus({commit, state, dispatch}, queueCommand){
    commit('load')
    try{
      const workflow = JSON.parse(JSON.stringify(this.state.workflow.workflow))
      const data = { "project":  workflow.project, "workflow_id": workflow.workflow_id, "command": queueCommand.command, "queue": queueCommand.queue }

      if(queueCommand.command==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Workflow cancelled by ${user.username}`})
      }

      const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      if(queueCommand.command === "delete"){
        router.back()
        dispatch('notify', {message:'Workflow has been deleted', status:true})
      }
      dispatch('notify', {message:`${queueCommand.command} command for ${workflow.name} sent`, status:true})
    }
    catch(e){
      dispatch('notify', {message:e.message, status:false})
    }
    commit('loaded')
  },
    ///
  /// JOBS RELATED ACTIONS
  ///
  clearWorkflowJobActions({commit}){
    commit('populateWorkflowJobActions', [])
  },
  loadWorkflowJobActions({commit, dispatch}, jobsToManage){
    try {
      // used by: Jobs.vue, WorkflowJobsSearch.vue

      // return the currently selected workflow and derive status
      let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
      let workflowStatus = workflow.status

      let statusesToInclude = []
      for(let i=0; i<jobsToManage.length; i++){
        if(!statusesToInclude.includes(jobsToManage[i].status)){
          statusesToInclude.push(jobsToManage[i].status)
        }
      }
      //todo: change this level
      let availableJobCommands = []

      let jobCommands = workflowActions.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

      if(jobCommands!==undefined){
        // loop through the statuses we need to include
        for(let i=0; i<jobCommands.jobCommandDict.length; i++){
          // loop through the job command dictionary to check each jobStatus
          for(let j=0; j<jobCommands.jobCommandDict[i].jobStatuses.length; j++){
            if(!availableJobCommands.includes(jobCommands.jobCommandDict[i].jobStatuses[j]) && statusesToInclude.includes(jobCommands.jobCommandDict[i].jobStatuses[j])){
              jobCommands.jobCommandDict[i].commands.forEach(jc => {
                if(!availableJobCommands.includes(jc)){
                  availableJobCommands.push(jc)
                }
              })
            }
          }
        }
      }
      commit('populateWorkflowJobActions', availableJobCommands)
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  loadJobActionsForSingleJob({commit}, job){
    // there's already a job ID and a workflowStatus ID
    const jobStatus = job.p2020_status
    let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
    let workflowStatus = workflow.status

    let wfCommandDict = workflowActions.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

    let commands = []

    if(wfCommandDict!==undefined){
      wfCommandDict.jobCommandDict.forEach(jobCommandItem => {
        if (jobCommandItem.jobStatuses.includes(jobStatus)){
          commands = jobCommandItem.commands
        }
      })
      commit('populateWorkflowJobActions', commands)
    }
  },
  loadStepStatusTableJobActions({commit}){
    let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
    let workflowStatus = workflow.status
    
    let wfCommandDict = workflowActions.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

    // TODO: turn this into a loop - forEach(commit(action, includes(status))) same with the else part
    if (wfCommandDict !== undefined){
      commit('updateUnknownJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('UNKNOWN')).map(jc => jc.commands)[0])
      commit('updateRunnableJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('RUNNABLE')).map(jc => jc.commands)[0])
      commit('updateSubmittedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('SUBMITTED')).map(jc => jc.commands)[0])
      commit('updatePendingJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('PENDING')).map(jc => jc.commands)[0])
      commit('updateRunningJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('RUNNING')).map(jc => jc.commands)[0])
      commit('updateCompletedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('COMPLETED')).map(jc => jc.commands)[0])
      commit('updateFailedJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('FAILED')).map(jc => jc.commands)[0])
      commit('updateIncompleteJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('INCOMPLETE')).map(jc => jc.commands)[0])
      commit('updateHeldJobActions', wfCommandDict.jobCommandDict.filter(jc => jc.jobStatuses.includes('HELD')).map(jc => jc.commands)[0])
    }
    else{
      commit('updateUnknownJobActions', [])
      commit('updateRunnableJobActions', [])
      commit('updateSubmittedJobActions', [])
      commit('updatePendingJobActions', [])
      commit('updateRunningJobActions', [])
      commit('updateCompletedJobActions', [])
      commit('updateFailedJobActions', [])
      commit('updateIncompleteJobActions', [])
      commit('updateHeldJobActions', [])
    }
  },
  selectWorkflowJobAction({commit}, input){
    commit('updateWorkflowJobAction', input.target.value) 
  },
  selectWorkflowJobStepAction({commit}, input){
    commit('updateWorkflowJobStepAction', input.target.value)
  },
  clearWorkflowJobAction({commit}){
    commit('updateWorkflowJobAction', "")
  },
  async updateWorkflowJobStatus({commit,state, dispatch}, jobDetails){
    commit('load')
    commit('jobLoad')
    try{
      let requestValid = true
      if(state.selectedJobAction==="change_queue"){
        // make sure no jobs are 'submitted'
        let jobs = JSON.parse(JSON.stringify(this.state.workflowjobs.workflowjobs))
        let jobsalreadySubmitted = jobs.some(j => j.status==="SUBMITTED")
        if(jobsalreadySubmitted){
          dispatch('notify', {message:"Queue cannot be changed: Job already has SUBMITTED status ", status:false})
          requestValid=false
        }
      }
      if(requestValid){
        let data = { "project":  jobDetails.project, "workflow_id": jobDetails.workflow_id, "command": state.selectedJobAction, "job_list": jobDetails.jobs }
        // i need to get the job details jobs definition id. 
        if(state.selectedJobAction==="rerun"){
          dispatch('rerunJobs', jobDetails)
        }
        else if(state.selectedJobAction==="rerun inputs"){
          dispatch('rerunDependentJobs', jobDetails)
        }
        else if(state.selectedJobAction==="cancel"){
          let user = await Auth.currentAuthenticatedUser()      
          Object.assign(data, {"reason": `Step Jobs cancelled by ${user.username}`})
        }
        else if(state.selectedJobAction==="force_run"){
          data.command="run"
          Object.assign(data, {"force": true})
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`Force Run command sent`, status:true})
        }
        else if(jobDetails.filters!==undefined && jobDetails.filters.level!==undefined && jobDetails.filters.level === "step"){
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`${state.selectedJobAction} command sent for all ${jobDetails.filters.status} jobs in ${jobDetails.filters.steps}`, status:true})
        }
        else{
          const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
          dispatch('notify', {message:`${state.selectedJobAction} command sent to ${data.job_list.length} jobs`, status:true})
        }
      }
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
    commit('jobLoaded')
  },

  async rerunJobs({commit,state, dispatch}, jobDetails){  
    commit('load')
    commit('jobLoad')
    try{
      const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))
      let data = { "project":  jobDetails.project, "workflow_id": jobDetails.workflow_id, "command": "", "job_list": jobDetails.jobs }
      // remove, release, retry
      data.command = "remove"
      const removeRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      await sleep(1000)
      data.command = "release"
      const releaseRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      await sleep(1000)
      data.command = "retry"
      const retryRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      dispatch('notify', {message:`${state.selectedJobAction} command sent`, status:true})
    }
    catch(e){
      console.log(e)
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
    commit('jobLoaded')
  },
  async fetchDependentJobs({commit, state, dispatch}, jobDetails){
    const workflowId = jobDetails.workflow_id
    const projectName = jobDetails.project
    let jobsList = jobDetails.jobs
    let dependentJobs = []
    // loop through each job in the jobs list
    for(var i=0; i<jobsList.length; i++){
      let step = jobsList[i].step
      let jobId = jobsList[i].job_id
      let res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_dependencies&get_status`, await authHeader())
      let inputFiles = res.data.inputs
      for(let j=0; i<inputFiles.length; j++){
        for(let k=0; k<inputFiles[j].urls.length; k++){
          for(let l=0; l<inputFiles[j].urls[k].provided_by.length; l++){
            dependentJobs.push([{"step": inputFiles[j].urls[k].provided_by[l].step, "job_id": inputFiles[j].urls[k].provided_by[l].job_id}])
          }
        }
      }
      commit("setDependentJobs", dependentJobs)
    }
  },
  async rerunDependentJobs({commit,state, dispatch}, jobDetails){
    try{
      let data = { "project":  jobDetails.project, "workflow_id": jobDetails.workflow_id, "command": state.selectedJobAction, "job_list": jobDetails.jobs }
      const workflowId = jobDetails.workflow_id
      const projectName = jobDetails.project
      let jobsList = jobDetails.jobs
      // loop through each job in the jobs list and get the necessary files
      for(var i=0; i<jobsList.length; i++){
        let step = jobsList[i].step
        let jobId = jobsList[i].job_id
        let res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_dependencies&get_status`, await authHeader())
        let inputFiles = res.data.inputs
        for(let j=0; j<inputFiles.length; j++){
          // go through each necessary input file to find out the url of each file
          for(let k=0; k<inputFiles[j].urls.length; k++){
            // go through each file url, find out which job provides that url and rerun that job
            for(let l=0; l<inputFiles[j].urls[k].provided_by.length; l++){
              data.jobs = [{"step": inputFiles[j].urls[k].provided_by[l].step, "job_id": inputFiles[j].urls[k].provided_by[l].job_id}]
              await dispatch("rerunJobs", data)
            }
          }
        }
      }
    }
    catch(e){
      console.log(e)
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
  },

  ///
  /// STEP RELATED ACTIONS
  ///
  stringActionDispatcher({dispatch}, functionToRun){
    dispatch(functionToRun)
  },
  stringActionOne({}){
    console.log('string action one')
  },
  clearWorkflowStepActions({commit}){
    commit('populateWorkflowStepActions', [])
  },
  loadWorkflowStepActions({commit, dispatch}, stepsToManage){
    try {      
      let workflow = JSON.parse(JSON.stringify(store.getters.getWorkflowForStepUpdate()))
      let workflowStatus = workflow.status

      let statusesToInclude = []
      for(let i=0; i<stepsToManage.length; i++){
        for(let j=0; j<stepsToManage[i].jobStepStatuses.length; j++){
          if(!statusesToInclude.includes(stepsToManage[i].jobStepStatuses[j])){
            statusesToInclude.push(stepsToManage[i].jobStepStatuses[j])
          }
        }
      }

      let availableStepCommands = []
      let jobCommands = workflowActions.superCommandDictionary
      .find(wf => wf.workflowStatus === workflowStatus)

      if(jobCommands!==undefined){
        // loop through the statuses we need to include
        for(let i=0; i<jobCommands.jobCommandDict.length; i++){
          // loop through the job command dictionary to check each jobStatus
          for(let j=0; j<jobCommands.jobCommandDict[i].jobStatuses.length; j++){
            if(!availableStepCommands.includes(jobCommands.jobCommandDict[i].jobStatuses[j]) && statusesToInclude.includes(jobCommands.jobCommandDict[i].jobStatuses[j])){
              jobCommands.jobCommandDict[i].commands.forEach(jc => {
                if(!availableStepCommands.includes(jc)){
                  availableStepCommands.push(jc)
                }
              })
            }
          }
        }
        commit('populateWorkflowStepActions', availableStepCommands)
      }

    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
    } 
  },
  async updateWorkflowJobStepStatus({commit, state, dispatch}, jobStepDetails){
    commit('load')
    try{
      let data = { "project":  jobStepDetails.project, "workflow_id": jobStepDetails.workflow_id, "command": state.selectedJobStepAction, "filters": jobStepDetails.filters }
      if(state.selectedJobStepAction==="rerun"){
        // remove, release, retry
        data.command = "remove"
        const removeRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        data.command = "release"
        const releaseRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        data.command = "retry"
        const retryRes = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      }
      else if(state.selectedJobStepAction==="rerun inputs"){
        // lets get all the jobs for this workflow, step and at this status
        let projectName = jobStepDetails.project
        let workflowId = jobStepDetails.workflow_id
        let filters = jobStepDetails.filters
        // filters is a level and two arraus. status and steps. 
        // for each step
        // get the jobs at that step and status
        for(var i=0; i<filters.steps.length;i++){
          let step = filters.steps[i]
          for(var j=0; j<filters.status.length;j++){
            let status = filters.status[j]
            let stepJobStatusRes=await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${step}?status=${status}`, await authHeader())
            let jobArgs = {
              "project":  projectName, 
              "workflow_id": workflowId, 
              "jobs": stepJobStatusRes.data.map(j => ({
                "step": step,
                "job_id": j.id
              }))
            }
            dispatch('rerunDependentJobs', jobArgs)
          }
        }
      }
      else if(state.selectedJobStepAction==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Step(s) procedure cancelled by ${user.username}`})
      }
      else if(state.selectedJobStepAction==="force run"){
        data.command="run"
        Object.assign(data, {"force": true})
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        dispatch('notify', {message:`Force Run command sent`, status:true})
      }
      else{
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      }
      dispatch('notify', {message:
        `${state.selectedJobStepAction} command sent to all ${jobStepDetails.filters.steps} 
          ${jobStepDetails.filters.status!==undefined?jobStepDetails.filters.status:""} 
        jobs `, status:true})
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
  },
  async updateWorkflowStepStatus({commit,state, dispatch}, stepDetails){
    commit('load')
    try{
      const data = { "project":  stepDetails.project, "workflow_id": stepDetails.workflow_id, "command": state.selectedStepAction, "filters": stepDetails.filters }
      if(state.selectedStepAction==="cancel"){
        let user = await Auth.currentAuthenticatedUser()      
        Object.assign(data, {"reason": `Step(s) procedure cancelled by ${user.username}`})
      }
      else if(state.selectedStepAction==="force_run"){
        data.command="run"
        Object.assign(data, {"force": true})
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
        dispatch('notify', {message:`Force Run command sent`, status:true})
      }
      else{
        const res = await axios.post(`${API_URL}/manage_workflow`, data, await authHeader())
      }
      dispatch('notify', {message:`${state.selectedStepAction} command sent to all ${stepDetails.filters.steps} jobs `, status:true})
    }
    catch(e){
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
    }
    commit('loaded')
  },
  selectWorkflowStepAction({commit}, input){
    commit('updateWorkflowStepAction', input.target.value) 
  },
  clearWorkflowStepAction({commit}){
    commit('updateWorkflowStepAction', "")
  },
}

export const getters = {
  getWorkflowActions: state => state.availableActions,
  getSelectedAction: state => state.selectedAction,
  getWorkflowJobActions: state => state.availableJobActions,
  getSelectedJobAction: state => state.selectedJobAction,
  getWorkflowStepActions: state => state.availableStepActions,
  getSelectedStepAction: state => state.selectedStepAction,
  getSelectedJobStepAction: state => state.selectedJobStepAction,
  getHeldJobActions: state => state.heldJobActions,
  getCompletedJobActions: state => state.completedJobActions,
  getSubmittedJobActions: state => state.submittedJobActions,
  getFailedJobActions: state => state.failedJobActions,
  getIncompleteJobActions: state => state.incompleteJobActions,
  getRunningJobActions: state => state.runningJobActions,
  getRunnableJobActions: state => state.runnableJobActions,
  getPendingJobActions: state => state.pendingJobActions,
  getUnknownJobActions: state => state.unknownJobActions,
  getDependentJobs: state => state.depdendentJobs
}