import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import store from '../../store'
import API_URL from '../../constants'


export const state = {
  templatesLoading: true,
  templates: [{}],
  sharedTemplates: []
}

export const mutations = {
  //cannot do async in mutations.
  populateTemplates(state, templates){
    state.templates = templates
  },
  populateSharedTemplates(state, sharedTemplates){
    state.sharedTemplates = sharedTemplates
  },
  loaded(state){
    state.templatesLoading = false
  },
  load(state){
    state.templatesLoading = true;
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadTemplates({commit, state, dispatch}){
    commit("load")
    try {
      let projectData = JSON.parse(JSON.stringify(store.getters.getProjectsForWorkflows()))
      let projects = projectData.filter(p => p.active).map(p => p.name)
      let templates = []
      for(var i = 0; i < projects.length; i++){
        let res = await axios.get(`${API_URL}/templates/${projects[i]}`, await authHeader())
        if(res.data.length > 0){
          templates = templates.concat(res.data)
          commit('populateTemplates', templates)
          commit('loaded')      
        }       
      }
      commit('load')
      let all_templates_res = await axios.get(`${API_URL}/templates/`, await authHeader())
      templates = templates.concat(all_templates_res.data)
      commit('populateTemplates', templates)
      commit('loaded')
    } catch (e) {
      dispatch('notify', {message:e.message, status:false})
      commit('loaded')      
    }    
  },
}

export const getters = {
  getTemplates: state => state.templates,
  templatesLoading: state => state.templatesLoading,
}