import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants'

export const state = {
  stepsLoading: true,
  steps: undefined,
  workflowStepsStatus: [{}],
  stepError: false,
  initialising: true,
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflowSteps(state, workflowSteps){
    state.steps = workflowSteps
  },
  populateWorkflowStepsStatus(state, workflowStatuses){
    state.workflowStepsStatus = workflowStatuses
  },
  replaceWorkflowStepData(state, workflowStepData){
    const stepIndex = state.workflowStepsStatus.findIndex((step) => step.step === workflowStepData.step)
    state.workflowStepsStatus[stepIndex] = workflowStepData
  },
  pushStepStatus(state, workflowStatus){
    if(state.initialising){
      state.workflowStepsStatus[0] = workflowStatus
      state.initialising=false;
    }
    else{
      state.workflowStepsStatus.push(workflowStatus)
    }
  },
  clearWorkflowStepsStatus(state){
    state.workflowStepsStatus = [{}]
  },
  stepStatusloaded(state){
    state.stepsStatusLoading = false
  },
  loadStepStatus(state){
    state.initialising = true
    state.stepsStatusLoading = true
  },
  loaded(state){
    state.stepsLoading = false
  },
  load(state){
    state.stepsLoading = true;
  },
  stepError(state, payload){
    state.stepError = payload
  }
}

export const actions = {
  async loadWorkflowSteps({commit, dispatch}, workflowStepDetails){
    commit("load")
    try {
      commit('stepError', workflowStepDetails)
      const workflowId = workflowStepDetails.workflowId
      const projectName = workflowStepDetails.projectName
      //get step and definition
      const res = await axios.get(`${API_URL}/steps/${projectName}/${workflowId}`, await authHeader())
      let steps = res.data
      if(workflowStepDetails.activeStep !== undefined){
        steps.forEach(step => step.active = step.step === workflowStepDetails.activeStep)
      }
      commit('populateWorkflowSteps', steps)
      commit('loaded')      
    } catch (e) {
      dispatch('notify', {message:"Error Loading Steps: " + JSON.parse(e.response.data).message, status:false})
      commit('stepError', true)
      commit('populateWorkflowSteps', [{}])
    }    
  },
  async loadWorkflowStepsStatus({state, commit, dispatch}, workflowStepDetails){
    commit("loadStepStatus")
    commit("clearWorkflowStepsStatus")
    try {
      commit('stepError', false)
      let steps = state.steps.map(s => s.step)
      const workflowId = workflowStepDetails.workflowId
      const projectName = workflowStepDetails.projectName
      //get step and definition
      for(var i=0; i < steps.length; i++){
        const res = await axios.get(`${API_URL}/steps_status/${projectName}/${workflowId}/${steps[i]}`, await authHeader())   
        let stepStatus = {step: steps[i], status: res.data.combined_statuses, no_status_record: res.data.no_status_record, not_in_step_file: res.data.not_in_step_file}
        // pip-447: add array of statuses to select. Used by WorkflowSteps to determine which job statuses are selected and derive correct actions
        let jobStepStatuses = []
        for(let i=0; i < Object.keys(stepStatus.status).length; i++){
          // if the number of jobs of that status is more than 0 AND if that value is not already in the last add the key. 
          if(Object.values(stepStatus.status)[i] > 0 && !jobStepStatuses.includes(Object.keys(stepStatus.status)[i])){
            jobStepStatuses.push(Object.keys(stepStatus.status)[i])
          }
        }
        stepStatus.jobStepStatuses = jobStepStatuses
        commit('pushStepStatus', stepStatus)
      }
      commit('stepStatusloaded')
    } catch (e) {
      console.log(e)
      dispatch('notify', {message:"Error Loading Steps: " + JSON.parse(e.response.data).message, status:false})
      commit('stepError', true)
      commit('populateWorkflowStepsStatus', [{}])
    }    
  },
  async refreshStepData({dispatch, commit}, stepRowDetails){
    try{
      const workflowId = stepRowDetails.workflowId
      const project = stepRowDetails.project
      const step = stepRowDetails.step
      const res = await axios.get(`${API_URL}/steps_status/${project}/${workflowId}/${step}`, await authHeader())
      let jobStepStatuses = []
      for(let i=0; i < Object.keys(res.data.combined_statuses).length; i++){
        // if the number of jobs of that status is more than 0 AND if that value is not already in the last add the key. 
        if(Object.values(res.data.combined_statuses)[i] > 0 && !jobStepStatuses.includes(Object.keys(res.data.combined_statuses)[i])){
          jobStepStatuses.push(Object.keys(res.data.combined_statuses)[i])
        }
      }
      let stepRowData = {step: step, status: res.data.combined_statuses, no_status_record: res.data.no_status_record, not_in_step_file: res.data.not_in_step_file, jobStepStatuses : jobStepStatuses}
      commit('replaceWorkflowStepData', stepRowData)
      dispatch('notify', {message:`${stepRowData.step} refreshed`, status:true})
    }
    catch(err){
      console.error(err)
    }
  }
}

export const getters = {
  getWorkflowSteps: state => state.steps,
  getWorkflowStepsStatus: state => state.workflowStepsStatus,
  stepsLoading: state => state.stepsLoading,
  stepsStatusLoading: state => state.stepsStatusLoading,
  stepError: state => state.stepError
}