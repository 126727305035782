import uuid from "uuid";

export const state = {
  notifications: [],
}

export const mutations = {
  //cannot do async in mutations.
  showNotification(state, payload){    
    state.notifications = [...state.notifications, payload]
  },
  hideNotification(state, payload){
    state.notifications = state.notifications.filter(n => n.id !== payload.id)
  },
  hideAllNotifications(state){
    state.notifications = []
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async notify({commit}, payload){
    try{
      const id = uuid.v4();
      let notification = {id, message: payload.message, status:payload.status}
      let timer = payload.status? 10000: 100000
      commit('showNotification', notification) 
      setTimeout(function () {
        commit('hideNotification', notification)
      }, timer);
    }
    catch(e){
      console.log('notify error', e)
    }
  },
  async resetNotification({commit}){
    commit('hideAllNotifications')
  },
  async hideNotification({commit}, payload){
    commit('hideNotification', payload)
  }
}

export const getters = {
  notifications: state => state.notifications
}