import { Auth } from 'aws-amplify'


export const portalAuthHeader = async() => {
  // return authorization header with jwt token
  const user = await Auth.currentAuthenticatedUser({bypassCache: false })   
  let token = user.signInUserSession.idToken.jwtToken

  if (token) {
      return { headers: {
            'Content-Type': 'application/json;', 
            'Authorization': token,
            'Verify': false
          }};
  } else {
      return {};
  }
}