import { Auth } from 'aws-amplify'

export async function verifyToken(){
  try{
    let user = await Auth.currentAuthenticatedUser({bypassCache: false })      
    return true
  }
  catch(err){
    return false
  }
}