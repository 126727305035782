import axios from 'axios'
import {portalAuthHeader} from '../../middleware/portal-header'
import {authHeader} from '../../middleware/auth-header'
import API_URL from '../../constants';

// TODO: parse local, dev, test and prod environments. 
const PORTAL_LOCAL_API_URL = `http://gsi-staging.surfaceintelligence.test/gcc/v1`
const PORTAL_TEST_API_URL = `https://gsi-staging.test.surfaceintelligence.com/gcc/v1`
const PORTAL_DEV_API_URL = `https://gsi-staging.dev.surfaceintelligence.com/gcc/v1`
const LIVE_PORTAL_API_URL = `https://gsi-staging.surfaceintelligence.com/gcc/v1`


const portalService = axios.create({
  baseURL: LIVE_PORTAL_API_URL,
  withCredentials: true,
  xsrfCookieName: 'csrf_access_token'
});


export const state = {
  project: null,
  layerTypes: undefined,
  projectWorkflows: undefined,
  projectWorkflowsLoading: false,
  steps: undefined,
  projectStepsLoading: false,
  jobs: undefined,
  projectJobsLoading: false,
  files: undefined,
  projectFilesLoading: false,
  urls: undefined,
  projectURLsLoading: false,
  allProjectFiles: [],
  allProjectLayers: [],
  allProjectDimensions: []
}

export const mutations = {
  populatePortalDetails(state, portalProjectDetails){
    state.project = portalProjectDetails
  },
  clearPortalDetails(state){
    state.project = null
  },
  populateLayerTypes(state, layerTypes){
    state.layerTypes = layerTypes
  },
  populateProjectWorkflows(state, workflows){
    state.projectWorkflows = workflows
  },
  populateProjectWorkflowSteps(state, steps){
    state.steps = steps
  },
  populateProjectJobs(state, jobs){
    state.jobs = jobs
  },
  populateProjectFiles(state, files){
    state.files = files
  },
  populateProjectURLs(state, urls){
    state.urls = urls
  },
  setProjectWorkflowsLoading(state){
    state.projectWorkflowsLoading = true
  },
  setProjectWorkflowsLoaded(state){
    state.projectWorkflowsLoading = false
  },
  setProjectStepsLoading(state){
    state.projectStepsLoading = true
  },
  setProjectStepsLoaded(state){
    state.projectStepsLoading = false
  },
  setProjectJobsLoading(state){
    state.projectJobsLoading = true
  },
  setProjectJobsLoaded(state){
    state.projectJobsLoading = false
  },
  setProjectFilesLoading(state){
    state.projectFilesLoading = true
  },
  setProjectFilesLoaded(state){
    state.projectFilesLoading = false
  },
  setProjectURLsLoading(state){
    state.projectURLsLoading = true
  },
  setProjectURLsLoaded(state){
    state.projectURLsLoading = false
  },
  populatePortalFileDetails(state, files){
    state.allProjectFiles = files
  },
  populatePortalLayerDetails(state, layers){
    state.allProjectLayers = layers
  },
  populatePortalDimensionDetails(state, dimensions){
    state.allProjectDimensions = dimensions
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadPortalProjectData({commit, dispatch}, project){
    try {
      const res = await portalService.get(`/project/${project}`, await portalAuthHeader())
      const res_layers = await portalService.get(`/project/layers/${project}`, await portalAuthHeader())
      const res_files = await portalService.get(`/project/files/${project}`, await portalAuthHeader())
      const res_dimensions = await portalService.get(`/project/dimensions/${project}`)
      commit('populatePortalDetails', res.data)
      commit('populatePortalLayerDetails', res_layers.data)
      commit('populatePortalFileDetails', res_files.data)
      commit('populatePortalDimensionDetails', res_dimensions.data)

    } catch (e) {
      dispatch('notify', {message:"Portal Load Failed", status:false})
    }    
  },
  wipePortalDetails({commit}) {
    commit('clearPortalDetails')
  }, 
  async updatePortalProject({commit, dispatch}, portalDetails){
    try{
      const res = await portalService.post('/', portalDetails, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async addPortalProject({commit, dispatch}, portalDetails){
    try{
      let bodyData = new FormData();
      bodyData.append('name', portalDetails.name)
      bodyData.append('uri', portalDetails.uri)
      bodyData.append('product_name', portalDetails.product)
      bodyData.append('user_name', portalDetails.username)
      bodyData.append('password', portalDetails.password)
      bodyData.append('is_public', false)

      const res = await portalService.post('/add_project/', bodyData, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async addLayer({commit, dispatch}, portalDetails){
    try{
      let bodyData = new FormData();
      bodyData.append('project_name', portalDetails.name)
      bodyData.append('name', portalDetails.layerName)
      bodyData.append('type', portalDetails.layerType)
      bodyData.append('user_name', portalDetails.username)
      bodyData.append('password', portalDetails.password)
      if(portalDetails.layerType==="raster"){
        if(portalDetails.minRange && portalDetails.maxRange){
          bodyData.append('range', portalDetails.range)
        }
      }

      if (portalDetails.dimensions){
        bodyData.append('dimensions', true)
        for (var i=0; i<portalDetails.dimensions.length;i++){
          bodyData.append('dimension_name', portalDetails.dimensions[i].dimensionName)
          bodyData.append('dimension_type', portalDetails.dimensions[i].dimensionType)
        }
      }

      if(portalDetails.unit){
        bodyData.append('unit', portalDetails.unit)
      }
      const res = await portalService.post(`/add_layer/`, bodyData, await portalAuthHeader())
      commit('populatePortalDetails', res.data)
    } catch (e) {
      dispatch('notify', {message:"Portal Update Failed: " + e.message, status:false})
    }
  },
  async editLayer({dispatch}, portalDetails){
    try{
      let bodyData = new FormData();
      bodyData.append('project_name', portalDetails.project_name)
      if (portalDetails.name){
        bodyData.append('name', portalDetails.name)
      }
      if (portalDetails.layerType){
        bodyData.append('layer_type', portalDetails.layerType)
      }
      if (portalDetails.dimensions){
        bodyData.append('dimensions', true)
        for (var i=0; i<portalDetails.dimensions.length;i++){
          bodyData.append('dimension_name', portalDetails.dimensions[i].dimensionName)
          bodyData.append('dimension_type', portalDetails.dimensions[i].dimensionValue)
        }
      }

      if(portalDetails.unit){
        bodyData.append('unit', portalDetails.unit)
      }
      bodyData.append('layer_id', portalDetails.layer_id)
      bodyData.append('user_name', portalDetails.username)
      bodyData.append('password', portalDetails.password)
      const res = await portalService.post(`/edit_layer/`, bodyData, await portalAuthHeader())
      dispatch('notify', {message:`Layer Update Successful`, status:true})
    }
    catch(error){
      dispatch('notify', {message:error.message, status:false})
    }
    
  },
  async loadLayerTypes({commit,dispatch}){
    try{
      const res = await portalService.get(`/layer_types/`, await portalAuthHeader())
      commit('populateLayerTypes', res.data.layer_types)
    }
    catch(e){
      dispatch('notify', {message:"Failure getting Layer Types" + e.message, status:false})
    }
  },
  async loadWorkflowsForPortalProject({commit, dispatch},project){
    commit('setProjectWorkflowsLoading')
    try{
      let res = await axios.get(`${API_URL}/workflows/${project}`, await authHeader())
      res.data.sort((a,b) => {var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    })
      commit('populateProjectWorkflows', res.data)
    }
    catch(e){
      dispatch('notify', {message:"Failure getting Workflows for Portal Project: " + e.message, status:false})
    }
    commit('setProjectWorkflowsLoaded')
  },
  async loadWorkflowStepsForPortalProject({commit, dispatch, state}, workflowId){
    commit('setProjectStepsLoading')
    commit('setProjectJobsLoading')
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectWorkflowSteps', undefined)
    commit('populateProjectJobs', undefined)
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)

    try {
      const projectName = state.project.name
      const res = await axios.get(`${API_URL}/steps/${projectName}/${workflowId}`, await authHeader())
      commit('populateProjectWorkflowSteps', res.data)
    } catch (e) {
      dispatch('notify', {message:"Failure getting Steps for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectStepsLoaded')
    commit('setProjectJobsLoaded')
    commit('setProjectFilesLoaded')
    commit('setProjectURLsLoaded')
  },
  async loadJobsForPortalProject({commit, dispatch, state}, wsDetails){
    commit('setProjectJobsLoading')
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)
    try {
      let step = wsDetails.workflowStep
      let workflowId = wsDetails.workflowId
      const projectName = state.project.name
      const res = await axios.get(`${API_URL}/jobs_status_by_step/${projectName}/${workflowId}/${step}`, await authHeader())
      commit('populateProjectJobs', res.data)
    } catch (e) {
      dispatch('notify', {message:"Failure getting Jobs for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectJobsLoaded')
    commit('setProjectFilesLoaded')
    commit('setProjectURLsLoaded')
  },
  async loadFilesForPortalProject({commit, dispatch, state}, wsjDetails){
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectURLs', undefined)

    try {
      let projectName = state.project.name
      let workflowId = wsjDetails.workflowId
      let step = wsjDetails.step
      let jobId = wsjDetails.jobId
      
      const res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobId}?get_status`, await authHeader())
      commit('populateProjectFiles', res.data.outputs.filter(file => file.urls.some(url => url.exists)))
    } catch (e) {
      dispatch('notify', {message:"Failure getting Jobs for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectURLsLoaded')
    commit('setProjectFilesLoaded')
  },
  async fetchAllFilesFromJobs({commit, state}, wsjDetails){
    commit('setProjectFilesLoading')
    commit('setProjectURLsLoading')
    commit('populateProjectURLs', undefined)

    let projectName = state.project.name
    let workflowId = wsjDetails.workflowId
    let step = wsjDetails.step
    let jobIds = state.jobs.map(job => job.id)
    let files = []
    for(var i=0; i<jobIds.length; i++){
      let res = await axios.get(`${API_URL}/job_files/${projectName}/${workflowId}/${step}/${jobIds[i]}?get_status`, await authHeader())
      let filtered_output_files = res.data.outputs.filter(file => file.urls.some(url => url.exists))
      filtered_output_files.forEach(file => file.urls.forEach(url => files.push(url)))
    }
    commit('populateProjectFiles', files)
    commit('setProjectURLsLoaded')
    commit('setProjectFilesLoaded')

  },
  async clearWorkflowStepData({commit}){
    commit('populateProjectWorkflowSteps', undefined)
    commit('populateProjectJobs', undefined)
    commit('populateProjectFiles', undefined)
    commit('populateProjectURLs', undefined)
  },
  async uploadFilesToLayer({commit, dispatch, state}, fileSendModel){
    try{
      let bodyData = new FormData();
      // Attach the common properties
      bodyData.append('project_name', fileSendModel.project)
      bodyData.append('layer', fileSendModel.layer)
      bodyData.append('files', fileSendModel.files.map(file => file.url))
      bodyData.append('user_name', fileSendModel.username)
      bodyData.append('password', fileSendModel.password)

      // Dimensions 
      if (fileSendModel.fileDimensions){
        for(var i = 0; i < fileSendModel.fileDimensions.length; i++){
          bodyData.append('dimension_name', fileSendModel.fileDimensions[i].dimensionName)
          bodyData.append('dimension_value', fileSendModel.fileDimensions[i].dimensionValue)
        }
      }
      // Process 'All' Channel/Band RGB selection - four bands, 1-4 with each channel
      if (fileSendModel.layerType==='rgb' && (!fileSendModel.channel || fileSendModel.channel==="")){
        // initialise channel and band then set each in the loop
        const channel_band_dict={"red":1, "green":2, "blue":3}
        for(const [key, value] of Object.entries(channel_band_dict)){
          bodyData.append('channel', key)
          bodyData.append('band', value)
        }
        // one post with all the channels
        await portalService.post(`/add_files/`, bodyData, await portalAuthHeader())
      }      
      else {
        // non vector so needs channel and band. Doesn't matter for RGB because channel has been chosen 
        bodyData.append('channel', fileSendModel.channel? fileSendModel.channel: "source")
        bodyData.append('band', fileSendModel.band? fileSendModel.band: 1)
        await portalService.post(`/add_files/`, bodyData, await portalAuthHeader())
      }
      dispatch('notify', {message:`File Upload Successful`, status:true})
    }
    catch(error){
      console.log('error in posting files: ', error.message)
      dispatch('notify', {message:`File Upload Unsuccessful`, status:false})

    }
  },
  uploadVectorFiles({commit, dispatch, state}, fileSendModel){},
  uploadRasterFiles({commit, dispatch, state}, fileSendModel){},
  loadURLsForPortalProject({commit, dispatch, state}, dataset){
    commit('setProjectURLsLoading')
    try {
      let urls = state.files.find(file => file.dataset===dataset)
      commit('populateProjectURLs', urls.urls)
    } catch (e) {
      dispatch('notify', {message:"Failure getting URls for Portal Project: " + e.message, status:false})
    }    
    commit('setProjectURLsLoaded')  
  }
}

export const getters = {
  getProject: state => state.project,
  getLayer: state => state.layer,
  getMessage: state => state.message,
  getlayerTypes: state => state.layerTypes,
  getProjectWorkflows: state => state.projectWorkflows,
  getProjectWorkflowSteps: state => state.steps,
  getJobsForPortalProject: state => state.jobs,
  getAllProjectLayers: state => state.allProjectLayers,
  getAllProjectFiles: state => state.allProjectFiles,
  getAllProjectDimensions: state => state.allProjectDimensions,
  getFilesForPortalProject: state => state.files,
  getURLSforPortalProject: state => state.urls,
  getProjectStepsLoading: state => state.projectStepsLoading,
  getProjectWorkflowsLoading: state => state.projectWorkflowsLoading,
  getProjectJobsLoading: state => state.projectJobsLoading,
  getProjectFilesLoading: state => state.projectFilesLoading,
  getProjectURLsLoading: state => state.projectURLsLoading
}