import { Auth } from 'aws-amplify'

export const state = {
  isAuthenticated: false,
  user: {}
}

export const mutations = {
  user(state, user){
    state.user = user
  },
  authenticateMutation(state, user){
    state.isAuthenticated = true
    state.user = user
  },
  logoutMutation(state, logoutResult){
    state.isAuthenticated = logoutResult;
    state.user = {};
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async authenticate({commit, dispatch}, authDetails){
    try {
      const username = authDetails.username;
      const password = authDetails.password;
      const user = await Auth.signIn(username, password)
      if(user.challengeParam){
        await Auth.completeNewPassword(user, authDetails.password);
      }
      commit('authenticateMutation', user)
    } catch (e) {
      dispatch('notify', {message:"Login Failed", status:false})
    }    
  },
  async checkAndRefreshToken({commit, dispatch}){
    try{
      let user = await Auth.currentAuthenticatedUser()      
      const expires = (await Auth.currentSession()).getAccessToken().payload.exp - Math.floor(new Date().getTime() / 1000)
      commit('authenticateMutation', user)
    }
    catch(err){
      dispatch('logout')
    }
  },
  async setUser({commit, dispatch}, user){
    try{
      commit('authenticateMutation', user)
    }catch(err){
      commit('authenticateMutation', null)
    }
  },
  async logout({commit}) {
    const res = await Auth.signOut()
    commit('logoutMutation', false)
  }
}

export const getters = {
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user
}