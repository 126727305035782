import axios from 'axios'
import {authHeader} from '../../middleware/auth-header'
import {portalAuthHeader} from '../../middleware/portal-header'
import API_URL from '../../constants'

export const state = {
  workflowLoading: true,
  workflow: {},
  workflowHistory: [],
  nextIteration: null,
  workflowConfig: null
}

export const mutations = {
  //cannot do async in mutations.
  populateWorkflow(state, workflow){
    state.workflow = workflow
  },
  populateWorkflowHistory(state, workflowHistory){
    state.workflowHistory = workflowHistory
  },
  loaded(state){
    state.workflowLoading = false
  },
  load(state){
    state.workflowLoading = true;
  },
  selectQueueMutation(state, queue){
    state.workflow.queue = queue
  },
  setNextIteration(state, nextIteration){
    state.nextIteration = nextIteration + 1
  },
  setWorkflowConfig(state, config){
    state.workflowConfig = config
  }
}

export const actions = {
  //cannot change data in state, must commit mutation but we can use async
  async loadWorkflow({commit, dispatch}, workflowDetails){
    commit("load")
    try {
      const workflowId = workflowDetails.workflowId
      const projectName = workflowDetails.projectName
      const res = await axios.get(`${API_URL}/workflows/${projectName}/${workflowId}`, await authHeader())
      commit('populateWorkflow', res.data)
      commit('loaded')
    } catch (e) {
      let data = JSON.parse(e.response.data)
      dispatch('notify', {message:data.message, status:false})
      commit('loaded')
    }    
  },
  async loadWorkflowHistory({commit, dispatch}, workflowDetails){
    commit("load")
    try{
      const project = workflowDetails.projectName
      const workflowName = workflowDetails.workflowName
      const res = await axios.get(`${API_URL}/workflows/${project}`, await authHeader())
      const filteredWorkflows = res.data.filter(wf => wf.name === workflowName).sort((a,b) => Number(a.iteration) - Number(b.iteration))
      commit("setNextIteration", filteredWorkflows.length)
      commit('populateWorkflowHistory', filteredWorkflows)
    }
    catch(e){}
    commit("loaded")
  },
  async loadWorkflowConfig({commit, dispatch}, workflowDetails){
    commit("load")
    try{
      let data = { "workflow":workflowDetails}
      const res = await axios.post(`${API_URL}/workflow_config`, data, await authHeader())
      console.log(res.data)
      commit("setWorkflowConfig", res.data)
    }
    catch(e){
      console.log('an error ocurred getting workflow config: ', e)
    }
    commit('loaded')
  },
  selectWorkflowQueue({commit}, queue){
    commit('selectQueueMutation', queue);
  },
}

export const getters = {
  getWorkflow: state => state.workflow,
  getWorkflowHistory: state => state.workflowHistory,
  getWorkflowStatus: state => state.workflow.status,
  getWorkflowForStepUpdate: state => () => state.workflow,
  getNextIteration: state => state.nextIteration,
  workflowLoading: state => state.workflowLoading,
  getWorkflowConfig: state => state.workflowConfig
}