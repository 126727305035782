import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'
import * as workflows from './modules/workflows'
import * as workflow from './modules/workflow'
import * as steps from './modules/steps'
import * as jobs from  './modules/jobs'
import * as job from './modules/job'
import * as queues from './modules/queues'
import * as templates from './modules/templates'
import * as workflowActions from './modules/workflowActions'
import * as auth from './modules/auth'
import * as template from './modules/template'
import * as notifications from './modules/notifications'
import * as projects from './modules/projects'
import * as urls from './modules/urls'
import * as portal from './modules/portal'
import * as jobConfig from './modules/jobconfig'
import * as stepConfig from './modules/stepConfig'



export default createStore({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    workflows,
    workflow,
    steps,
    jobs,
    job,
    queues,
    templates,
    workflowActions,
    auth,
    template,
    notifications,
    projects,
    urls,
    portal,
    jobConfig,
    stepConfig
  },
});
