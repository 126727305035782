<template>
  <div class="project-select">
    <p>Project: 
      <SelectList :items="projects.map(p => p.name)" :message="'Choose Project'" :selectedModel="chosenProject" :showClear="false" @filterList="handleSelectProject" />
    </p>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SelectList from '../components/Generics/SelectList.vue'

export default {
  name: 'ProjectSelect',
  components: {SelectList},
  computed: {
    ...mapGetters({
      projects: "getProjects",
      chosenProject: "getChosenProject",
    }),
  },
  methods: {
    ...mapActions(['loadProjects', 'selectProject']),
    handleSelectProject(project){
      this.selectProject(project)
    }
  },
  async mounted () {
    await this.loadProjects()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project-select{
  margin:auto;
  width:15rem;
  text-align: center;
}
</style>
